<template>
  <div class="content-user">
    <div v-for="(item, index) in useList" :key="index" class="content-item">
      <div class="content-view">
        <img
          class="content-avatar"
          :src="item.fullAvatar ? item.fullAvatar : require('@/assets/images/default-avatar.png')"
        />
        <div class="content-name">
          {{ item.staffName }}
        </div>
      </div>
      <div class="content-style" v-show="useList.length - 1 !== index"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'userAvatarTemplate',
  props: {
    useList: {
      type: Array,
      default: () => []
    }
  },
  setup(props, ctx) {
    return {}
  }
}
</script>
<style lang="less" scoped>
.content-user {
  display: flex;
  padding: 8px 16px 0;
  flex-wrap: wrap;
  .content-item {
    display: flex;
    margin-bottom: 10px;
    .content-view {
      display: flex;
      flex-direction: column;
      align-items: center;
      .content-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .content-name {
        width: 41px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        margin-top: 4px;
      }
    }

    .content-style {
      width: 40px;
      height: 1px;
      border: 1px solid #c3161c;
      margin: 20px 8px;
    }
  }
}
</style>
