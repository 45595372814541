// 电子用印申请引用 相关 接口APi
import request from '@/utils/request'

// 添加电子用印申请
export function addElecApply(params) {
  return request({
    url: '/pc/qys/apply',
    method: 'POST',
    data: params
  })
}

// 获取添加用印所有印章
export function getApplySealList(params) {
  return request({
    url: `/pc/details/seal/show`,
    method: 'GET',
    params
  })
}

// 获取该条电子印章申请的数据
export function getIdDetail(id) {
  return request({
    url: `/pc/qys/apply/details/resubmit/${id}`,
    method: 'GET'
  })
}

// 重新提交回显用印申请
export function resubmitElecApply(params) {
  return request({
    url: '/pc/qys/apply/resubmit',
    method: 'POST',
    data: params
  })
}

// 查看用印申请
export function elecApplyDetail(id) {
  return request({
    url: '/pc/qys/apply/details/' + id,
    method: 'GET'
  })
}

// 下载文件
export function downFile(id, params) {
  return request({
    url: '/pc/qys/contract/download/' + id,
    method: 'GET',
    params,
    responseType: 'blob'
  })
}

// 获取在线文件
export function getLineFile() {
  return request({
    url: '/digital/seal/reviewList',
    method: 'GET'
  })
}

// 上传在线文件
export function uploadLineFile(params) {
  return request({
    url: '/digital/seal/upload',
    method: 'POST',
    data: params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// 批量上传在线文件
export function uploadLineFileMul(params) {
  return request({
    url: '/digital/seal/upload/batch',
    method: 'POST',
    data: params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
